<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_conductor_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Conductores</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="tipo_documento" class="col-md-5"
                      >Tipo de Documento</label
                    >
                    <select
                      id="tipo_documento"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_documento"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_documento in listasForms.tipo_documentos"
                        :key="tipo_documento.numeracion"
                        :value="tipo_documento.numeracion"
                      >
                        {{ tipo_documento.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="numero_documento" class="col-md-5"
                      >Numero de Documento</label
                    >
                    <input
                      type="number"
                      id="numero_documento"
                      v-model="filtros.numero_documento"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="nombres" class="col-md-5">Nombres</label>
                    <input
                      type="text"
                      id="nombres"
                      v-model="filtros.nombres"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="representante" class="col-md-5"
                      >Apellidos</label
                    >
                    <input
                      type="text"
                      id="apellidos"
                      v-model="filtros.apellidos"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="email" class="col-md-5"> Email</label>
                    <input
                      type="text"
                      id="email"
                      v-model="filtros.email"
                      class="col-md-7"
                    />
                  </div>

                  <div class="form-group row">
                    <label for="num_lincencia_conduccion" class="col-md-5"
                      >Numero de Licencia</label
                    >
                    <input
                      type="number"
                      id="num_lincencia_conduccion"
                      v-model="filtros.num_lincencia_conduccion"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="localidad" class="col-md-5">Localidad</label>
                    <select
                      id="localidad"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.localidad"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="localidad in listasForms.localidades"
                        :key="localidad.numeracion"
                        :value="localidad.numeracion"
                      >
                        {{ localidad.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="categoria_licencia" class="col-md-5"
                      >Categoria de Licencia</label
                    >
                    <select
                      id="categoria_licencia"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.categoria_licencia"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="categoria_licencia in listasForms.categoria_licencias"
                        :key="categoria_licencia.numeracion"
                        :value="categoria_licencia.numeracion"
                      >
                        {{ categoria_licencia.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_sangre" class="col-md-5"
                      >Tipo de Sangre</label
                    >
                    <select
                      id="tipo_sangre"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_sangre"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_sangre in listasForms.tipo_sangres"
                        :key="tipo_sangre.numeracion"
                        :value="tipo_sangre.numeracion"
                      >
                        {{ tipo_sangre.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="empresas" class="col-md-5">Empresa</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.empresas"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="empresa in listasForms.empresas"
                        :key="empresa.id"
                        :value="empresa.id"
                      >
                        {{ empresa.razon_social }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_documento1"
                              v-model="form.tipo_documento"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_documento1"
                              >Tipo de Documento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="numero_documento1"
                              v-model="form.numero_documento"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="numero_documento1"
                              >Numero de Documento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombres1"
                              v-model="form.nombres"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombres1"
                              >Nombres</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="apellidos1"
                              v-model="form.apellidos"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="apellidos1"
                              >Apellidos</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="email1"
                              v-model="form.email"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="email1"
                              >Email</label
                            >
                          </div>
                        </div>

                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="celular_principal"
                              v-model="form.celular_principal"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="celular_principal"
                              >Celular Principal</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="celular_alterno"
                              v-model="form.celular_alterno"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="celular_alterno"
                              >Celular Alterno</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="num_lincencia_conduccion1"
                              v-model="form.num_lincencia_conduccion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="num_lincencia_conduccion1"
                              >Numero de Licencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="localidad1"
                              v-model="form.localidad"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="localidad1"
                              >Localidad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="categoria_licencia1"
                              v-model="form.categoria_licencia"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="categoria_licencia1"
                              >Categoria de Licencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_sangre1"
                              v-model="form.tipo_sangre"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_sangre1"
                              >Tipo de Sangre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="edad"
                              v-model="form.edad"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="edad"
                              >Edad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_reactivacion"
                              v-model="form.fecha_reactivacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_reactivacion"
                              >Fecha de Reactivación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresas"
                              v-model="form.empresas"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresas"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lista_documentos"
                              v-model="form.lista_documentos"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lista_documentos"
                              >Lista de Documentos</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_creacion"
                              v-model="form.fecha_creacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_creacion"
                              >Fecha Creación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_modificacion"
                              v-model="form.fecha_modificacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_modificacion"
                              >Fecha Modificación</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExce()"
                v-if="$store.getters.can('admin.conductores.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "ConductorExport", //llegada tambien
  //components: { vSelect },
  components: { 
    Loading,
    },
  data() {
    return {
      cargando: false,
      form: {
        tipo_documento: true,
        numero_documento: true,
        nombres: true,
        apellidos: true,
        email: true,
        celular_principal: true,
        celular_alterno: true,
        num_lincencia_conduccion: true,
        localidad: true,
        categoria_licencia: true,
        tipo_sangre: true,
        edad: true,
        fecha_reactivacion: true,
        empresas: true,
        lista_documentos: true,
        fecha_creacion: true,
        fecha_modificacion: true,
      },
      filtros: {
        tipo_documento: null,
        numero_documento: null,
        nombres: null,
        apellidos: null,
        email: null,
        num_lincencia_conduccion: null,
        localidad: null,
        categoria_licencia: null,
        tipo_sangre: null,
        estado: null,
        empresas: null,
      },

      listasForms: {
        tipo_documentos: [],
        localidades: [],
        empresas: [],
        categoria_licencias: [],
        tipo_sangres: [],
        estados: [],
      },
    };
  },

  methods: {
    getTipoDocumentos() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_documentos = response.data;
      });
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    getLocalidades() {
      axios.get("/api/lista/22").then((response) => {
        this.listasForms.localidades = response.data;
      });
    },

    getCategoriaLicencias() {
      axios.get("/api/lista/17").then((response) => {
        this.listasForms.categoria_licencias = response.data;
      });
    },
    getTipoSangres() {
      axios.get("/api/lista/16").then((response) => {
        this.listasForms.tipo_sangres = response.data;
      });
    },
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    generarListadoExce() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/conductores/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    await this.getTipoDocumentos();
    await this.getLocalidades();
    await this.getCategoriaLicencias();
    await this.getTipoSangres();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
